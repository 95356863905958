import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';

const routes: Routes = [
    { path: ':language?', component: HomeComponent },
    { path: '**', pathMatch: 'full', redirectTo: '/' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
