import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from "src/app/services/language.service"
import { Location } from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(
        private titleService: Title,
        private metaService: Meta,
        private translateService: TranslateService,
        private location: Location,
        private languageService: LanguageService
    ) { }

    ngOnInit(): void {
        this.languageService.initLanguage()

        this.titleService.setTitle("Samuel Costa | Software Engineer");

        this.metaService.addTags([
            { name: 'keywords', content: 'Full Stack, Software Enginner, Developer, Kotlin, Flutter, Python, Javascript, Typescript, Java, PHP, Node, Go, SQL' },
            { name: 'description', content: 'Desenvolvedor Sênior com mais de 9 anos de experiência em sistemas escaláveis. Atua no PicPay usando Java, Kotlin, Python e AWS. Experiência em projetos no Banco Votorantim, STJ, Faros Educacional, Stefanini e BRB. Formado em Ciência da Computação, é proativo, organizado e busca inovações.' },
        ]);

        AOS.init();
    }
}
