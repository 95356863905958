export const environment = {
    production: false,
    trackAnalyticID: "G-4ML1VNCP9T",
    firebase: {
        apiKey: "AIzaSyAryWXbBPapqN57CaMpWWhVrO7rtwq2NUU",
        authDomain: "pscc-a50aa.firebaseapp.com",
        projectId: "pscc-a50aa",
        storageBucket: "pscc-a50aa.appspot.com",
        messagingSenderId: "944463731924",
        appId: "1:944463731924:web:1bf54d5f272118425cef41",
        measurementId: "G-KMRBSXWYQ1"
    }
};
